<template>
  <div>
    <!-- <div class="d-block d-sm-none" style="background-color: #f5f5f5;">
      <b-card
        v-for="(order, key) of orders"
        :key="key"
        class="mb-2"
        @click="$router.push({ name: 'commandeDetail', params: { id: order.number } })"
      >
        <b-card-header>
          <div>
            <b-card-title class="d-flex font-weight-bolder">
              <span class="col-8">{{ `${order.number} - ${order.shipping.first_name} ${order.shipping.last_name}` }}</span>
              <div class="col-4">
                <feather-icon
                  v-if="resolveStatusIcon(order.status)"
                  :icon="resolveStatusIcon(order.status)"
                  class="ml-auto"
                  size="32"
                  :class="`text-${resolveStatusIconColor(order.status)}`"
                />
                <b-badge
                  v-else
                  pill
                  :variant="`light-primary`"
                  class="text-capitalize"
                >
                  {{ resolveStatusTranslation(order.status) }}
                </b-badge>
                <img
                  style="width: 30px; margin-left: 10px;"
                  :src="require(`@/assets/images/flags/${imageCountry(order.number.slice(0, 2))}`)"
                >
              </div>
            </b-card-title>
            <b-card-sub-title class="mb-25">
              {{ order.total }}€ - {{ $moment(order.date_created).format('hh:mm / DD-MM-YYYY') }}
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-text class="mt-1">
          <ul>
            <li
              v-for="(item, keyI) of order.date_created"
              :key="keyI"
            >
              {{ item.quantity }} x {{ item.name }}
            </li>
          </ul>
          <p v-if="order.coupon_lines && order.coupon_lines.length">
            <strong>Code promo:</strong> {{ order.coupon_lines.map(c => c.code).join(' / ') }}
          </p>
          <p v-if="order.payment_method_title">
            <strong>Paiement:</strong> {{ order.payment_method_title }}
          </p>
        </b-card-text>
        <b-link
          class="card-link d-flex justify-content-end"
          @click="$router.push({ name: 'commandeDetail', params: { id: order.number } })"
        >
          Voir la commande
        </b-link>
      </b-card>
    </div> -->
    <div class="d-none d-sm-block">
      <b-card
        no-body
        class="mb-0"
      >

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="orders"
          responsive
          :fields="tableColumns"
          primary-key="id"
          empty-text="Aucune commande trouvée"
        >

          <template #cell(order)="data">
            <b-media vertical-align="center">
              <b-link
                :to="{ name: 'commandeDetail', params: { id: data.item.number } }"
                class="font-weight-bold d-block text-nowrap font-size-sm"
              >
                {{ data.item.shipping.first_name }} {{ data.item.shipping.last_name }}
                <br>
              </b-link>
              <small class="text-muted">{{ data.item.number }}</small>
              <br>
              <span v-if="data.item.dateShipping" class="text-warning"><u>Livraison décalée:</u> {{ $moment(data.item.dateShipping).format('DD/MM/YYYY') }}</span>
            </b-media>
          </template>

          <template #cell(dispatch)="data">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(data.item.dispatch)}`"
              class="text-capitalize"
            >
              {{ data.item.dispatch ? data.item.dispatch : 'Non dispatché' }}
            </b-badge>
            <b-badge
              v-if="!data.item.dispatch && $moment(new Date(data.item.date_created)).isBefore($moment().subtract(10, 'days'))"
              pill
              :variant="`light-danger`"
              class="text-capitalize mt-1"
            >
              PROBLEME
            </b-badge>
          </template>

          <template #cell(status)="data">
            <feather-icon
              v-if="resolveStatusIcon(data.item.status)"
              :icon="resolveStatusIcon(data.item.status)"
              class="ml-auto"
              size="24"
              :class="`text-${resolveStatusIconColor(data.item.status)}`"
            />
            <b-badge
              v-else
              pill
              :variant="`light-primary`"
              class="text-capitalize"
            >
              {{ resolveStatusTranslation(data.item.status) }}
            </b-badge>
          </template>

          <template #cell(date)="data">
            <span>
              {{ $moment(data.item.date_created).format('DD/MM/YYYY') }}
            </span>
          </template>

          <template #cell(dateDispatch)="data">
            <span v-if="data.item.date">
              {{ $moment(new Date(data.item.date)).format('DD/MM/YYYY') }}
            </span>
          </template>

          <template #cell(product)="data">
            <span
              v-b-tooltip.hover
              :title="`${data.item.quantity} x ${data.item.name}`"
              variant="gradient-primary"
            >
              {{ `${data.item.quantity} x ${data.item.name} (${data.item.sku})` }}
            </span>
          </template>

          <template #cell(country)="data">
            <img
              style="width: 30px;"
              :src="require(`@/assets/images/flags/${imageCountry(data.item.number.slice(0, 2))}`)"
            >
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BTable, BMedia,
  BBadge, BLink, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BTable,
    BMedia,
    BLink,
    BBadge,
    // BTooltip,
    // BCardText,
    // vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [],
      avatarText,
    }
  },
  async mounted() {
    const tableColumns = [
      { key: 'order', label: 'Client', sortable: true },
      { key: 'dispatch', label: 'Dispatché', sortable: true },
      { key: 'logistic', label: 'Log.', sortable: true },
      { key: 'shipper', label: 'Ship.', sortable: true },
      { key: 'dispatch', label: 'Dispatché', sortable: true },
      { key: 'status', label: 'Statut', sortable: true },
      { key: 'date', label: 'Date Achat', sortable: true },
      { key: 'dateDispatch', label: 'Date disp.', sortable: true },
      { key: 'product', label: 'Produits', sortable: true },
      { key: 'country', label: 'Pays', sortable: true },
    ]
    this.tableColumns = tableColumns
  },
  methods: {
    resolveUserStatusVariant(type) {
      if (type === 'admin') return 'success'
      if (type === 'user') return 'secondary'
      return 'primary'
    },
    imageCountry(type) {
      if (type === 'FR') return 'france.svg'
      if (type === 'DE') return 'allemagne.svg'
      if (type === 'ES') return 'espagne.svg'
      if (type === 'IT') return 'italie.svg'
      if (type === 'PT') return 'portugal.svg'
      if (type === 'EN') return 'en.png'
      if (type === 'NL') return 'netherlands.png'
      return 'france.svg'
    },
    resolveStatusVariant(status) {
      console.log(['Non Dispatché'].includes(status), status)
      if (['Non Dispatché', null].includes(status)) return 'warning'
      if (['Dispatché'].includes(status)) return 'success'
      if (['Annulé'].includes(status)) return 'secondary'
      return 'info'
    },
    resolveStatusIconColor(status) {
      switch (status) {
        case 'processing':
          return 'success'
        case 'pending':
          return 'success'
        case 'on-hold':
          return 'warning'
        case 'completed':
          return 'warning'
        case 'failed':
          return 'danger'
        case 'cancelled':
          return 'primary'
        case 'refunded':
          return 'primary'
        default:
          return false
      }
    },
    resolveStatusIcon(status) {
      switch (status) {
        case 'processing':
          return 'ToolIcon'
        case 'pending':
          return 'ClockIcon'
        case 'on-hold':
          return 'MinusCircleIcon'
        case 'completed':
          return 'CalendarIcon'
        case 'failed':
          return 'AlertCircleIcon'
        case 'cancelled':
          return 'XCircleIcon'
        case 'refunded':
          return 'RepeatIcon'
        default:
          return false
      }
    },
    resolveStatusTranslation(status) {
      switch (status) {
        case 'processing':
          return 'En fabrication'
        case 'pending':
          return 'En attente de paiement'
        case 'on-hold':
          return 'En attente'
        case 'sended':
        case 'completed':
          return 'Expédiée'
        case 'failed':
          return 'Echouée'
        case 'cancelled':
          return 'Annulée'
        case 'refunded':
          return 'Remboursée'
        case 'rembourse-partiel':
          return 'Remboursée partiellement'
        default:
          return status
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
